/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import your custom fonts here if needed */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* You can add any additional global styles here */
body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}